<!--课程封面组件，部分完成，后续可以继续封装 -- 刘伟成 --7.9日-->
<template>
  <div class="sourceMain">
    <p class="sourceTitle">
      <span>{{ resdata.typeName }}</span>
    </p>
    <div class="sourceCover">
      <div class="sourceCover-source-logo">
        <img :src="resdata.img" alt />
      </div>
      <div class="sourceCover-content">
        <div>
          <p>{{ resdata.name }}</p>
          <ul class="source-introduce">
            <li v-for="(item, index) in (resdata.categories ? resdata.categories.split(',') : [])" :key="index">{{ item
            }}</li>
          </ul>
        </div>
        <ul class="source-descripton">
          <li>
            <h3>适用于</h3>
            <p>{{ resdata.scope }}</p>
          </li>
          <li>
            <h3>提供方</h3>
            <p>{{ resdata.provider }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["resdata"],
  data: function () {
    return {};
  },
  mounted() { },
};
</script>

<style>
.sourceMain {
  padding: 16px 17px 18px 13px;
  width: 328px;
  min-height: 170px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.sourceTitle {
  display: flex;
  align-items: center;
  column-gap: 3px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.sourceTitle::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 16px;
  background: #333333;
  border-radius: 8px;
  vertical-align: center;
}

.sourceCover {
  display: flex;
  justify-content: start;
}

.sourceCover>.sourceCover-source-logo>img {
  width: 86px;
  height: 115px;
  border-radius: 10px;
}

.sourceCover>.sourceCover-content {
  padding-left: 10px;
}

.sourceCover>.sourceCover-content>div {
  width: 202px;
  min-height: 72px;
  margin-bottom: 8px;
}

.sourceCover>.sourceCover-content p {
  width: 202px;
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sourceCover>.sourceCover-content .source-introduce {
  margin-top: 6px;
  display: flex;
  font-size: 12.5px;
  flex-wrap: wrap;
  width: 140%;
  transform: scale(0.8);
  transform-origin: left center;
}

.sourceCover>.sourceCover-content .source-introduce>li {
  box-sizing: content-box;
  padding: 0px 12px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 2px;
  background-color: #f3f3f3;
  border-radius: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  text-align: center;
}

.sourceCover>.sourceCover-content>.source-descripton {
  display: flex;
  column-gap: 30px;
  transform: scale(0.8) translate(0px, -4px);
  transform-origin: left center;
}

.sourceCover>.sourceCover-content>.source-descripton h3 {
  font-size: 15px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.sourceCover>.sourceCover-content>.source-descripton p {
  display: inline-block;
  margin-top: 4px;
  max-width: 80px;
  font-size: 12.5px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>